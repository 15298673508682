import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/SEO'

const NotFoundPage = () => {
    const { 
        options
      } = useStaticQuery(query)
// console.log(options)
    return (
        <div>
            <SEO
            page_title={options.title}
            meta_description={options.baseline_footer}
            poster={options.logotype.file.url}
            bodyClass="template-404 "
            // pathname={location.pathname} 
            />


            <div 
            className="texte fL"
            dangerouslySetInnerHTML={{ __html: options.message_404.childMarkdownRemark.html }}></div>
        </div>
    );
};
export default NotFoundPage;

const query = graphql`
  query NotFoundPage {
    options: contentfulOptions{
      ...options
    }    
 
    
  }
`